
import { Vue, Component } from 'vue-property-decorator';
import BaseButton from '@/components/landingAndRegistrationComponents/BaseButtonLanding.vue';
import LoginComponent from '@/components/landingAndRegistrationComponents/LoginComponent.vue';

@Component({
  components: { BaseButton, LoginComponent },
})
export default class LandingPaymentPage extends Vue {
  benefits = [
    {
      img: 'access',
      title: 'Access all of your bank accounts in one place',
      text: 'Maintain a complete overview of your finances. Manage payments and connect to your bank transactions for full visibility of your accounts and finances all in one place. Eliminate manual data entry and simplify payment acceptances with automatic reconciliation. The accounts payable and banking features include purchase orders, invoices, payments, bank transactions, and account reconciliation.',
      id: 1,
    },
    {
      img: 'cash',
      title: 'Manage your cash flow',
      text: 'Make sure you have enough cash to cover upcoming regular payments! The cash management feature gives you an instant snapshot of your cash flow situation. It shows you the balances of your cash accounts, the cash expected to come in, and the cash expected to go out. It gives you a clear picture of your finances whenever you need it. Remember, cash flows are the lifeblood of your company.',
    },
    {
      img: 'payments',
      title: 'Get a calendar view of upcoming payments',
      text: "Imagine all transactions posted to your calendar app with both amount debited/credited and also the expected balance at that point in time. We'll show you when money comes into and out of your account based on scheduled transfers, income and payment patterns. Reduce the chances of being caught unaware without enough cash to cover your expenses - you may even be able to apply for a line of credit directly through the platform to cover any expected cash flow gaps.",
    },
    {
      img: 'alerts-and-ingsights',
      title: 'Get actionable insights & smart alerts',
      text: 'Assess your small business health and access actionable insights from your favorite business apps, with 160+ app integrations. Data is aggregated in real-time and cross-analyzed to better understand current issues facing your business, and how to resolve them. These highly tailored insights give your business a live playbook as to your next steps towards greater profitability.',
    },
    {
      img: 'sales',
      title: 'Take your sales to the new level  ',
      text: 'Get vital sales insights using CreditPush and turn analytics into action. Get a summary of your business stats: orders, revenue, net profit, shipping costs, average order size - all the insights and recommendations that you need to grow sales both online and offline.',
    },
  ];

  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
